video::-webkit-scrollbar {
  display: none;
}

.trapezoid {
  border-top: 24px solid #fff7e130;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  height: 0;
  width: 24px;
}

.top-14 {
  top: 3.5rem;
}

.top-18 {
  top: 4.5rem;
}

.top-22 {
  top: 5.5rem;
}

.top-46 {
  top: 11.5rem;
}

.right-14 {
  right: 3.5rem;
}

.w-14 {
  width: 3.5rem;
}

.w-18 {
  width: 4.5rem;
}

.w-22 {
  width: 5.5rem;
}

.w-30 {
  width: 7.5rem;
}

.w-34 {
  width: 8.5rem;
}

.w-46 {
  width: 11.5rem;
}

.h-14 {
  height: 3.5rem;
}

.h-18 {
  height: 4.5rem;
}

.h-22 {
  height: 5.5rem;
}

.h-26 {
  height: 6.5rem;
}

.h-30 {
  height: 7.5rem;
}

.h-42 {
  height: 10.5rem;
}

.max-h-100 {
  max-height: 30rem;
}

.vibrate {
  animation: vibration 0.15s infinite;
}

@keyframes vibration {
  from {
    transform: rotate(4deg);
  }
  to {
    transform: rotate(-4deg);
  }
}

.scale-card {
  /* display: inline-block; */
  animation: scale 1s ease-in-out infinite;
}

.scale {
  /* display: inline-block; */
  animation: scale 1.6s ease-in-out infinite;
}

@keyframes scale {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

.row-of-cards > .card-wrapper:nth-of-type(2) > .scale-card {
  animation-duration: 1s;
  animation-delay: 0.5s;
}

.fade-in {
  opacity: 0;
  animation: fade-in 2s ease-in forwards, spin3d 1s linear;
  /* animation-delay: 1s; */
}

@keyframes fade-in {
  to {
    opacity: 0.5;
  }
}

.fade-in-100 {
  opacity: 0;
  animation: fade-in-100 0.5s ease-in forwards;
}

@keyframes fade-in-100 {
  to {
    opacity: 1;
  }
}

.fade-out-0 {
  opacity: 1;
  animation: fade-out-0 0.5s ease-in forwards;
}

@keyframes fade-out-0 {
  to {
    opacity: 0;
  }
}

@keyframes spin3d {
  from {
    transform: rotate3d(0, 1, 0, 0deg);
  }

  to {
    transform: rotate3d(0, 1, 0, 360deg);
  }
}

.grow {
  animation: grow 1.2s;
}

@keyframes grow {
  from {
    transform: scale(0.1);
  }
  to {
    transform: scale(1);
  }
}

.press-like {
  transform-origin: left bottom;
  animation: press 500ms ease-out 3;
}

@keyframes press {
  0%,
  100% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(-30deg);
  }
}

.flip-horizontal {
  --transform-scale-x: -1;
}

.slide {
  animation: slide 150s linear infinite;
}

@keyframes slide {
  0% {
    transform: translate3d(0, 0, 0);
  }
  100% {
    transform: translate3d(-9800px, 0, 0); /* The image width */
  }
}

.move-down {
  animation: move-down 0.5s;
  top: 0;
}

@keyframes move-down {
  0% {
    transform: translateY(-100px);
  }
  100% {
    transform: translateY(0px);
  }
}

.move-up {
  animation: move-up 0.5s;
}

@keyframes move-up {
  0% {
    transform: translateY(0px);
  }
  100% {
    transform: translateY(-100px);
  }
}

.slide-from-left {
  animation: 1s slide-from-left;
}
@keyframes slide-from-left {
  from {
    margin-left: -200%;
  }
  to {
    margin-left: 0%;
  }
}

.slide-to-right {
  animation: 1s slide-to-right;
}
@keyframes slide-to-right {
  from {
    margin-left: 0%;
  }
  to {
    margin-left: 200%;
  }
}

.arrow {
  transition: all 0.3s ease-in-out;
}

.arrow.active {
  transform: rotate(180deg);
}

.friend {
  transition: all 0.3s ease-in-out;
  height: 5.25rem;
}

.friend.active {
  height: 5%;
}
